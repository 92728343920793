import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext
} from "react";
import {
  makeStyles,
  Typography,
  Button,
  MenuItem,
  Link
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAppState } from "../../../state";
// import UserAvatar from "./UserAvatar/UserAvatar";
import BellIcon from "../../../icons/BellIcon";
import Menu from "@material-ui/core/Menu";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { SyncClient } from "twilio-sync";
import TokenContextProvider from "../../../TokenContext";

// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import WaitingListContext from "../../../WitingListContext";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  userContainer: {
    position: "relative",
    top: 0,
    background: "#fff",
    right: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
    columnGap: "15px",
    width: "100%",
    padding: "0",
    justifyContent: "left",
    borderBottom: "2px solid #f50257",
    fontWeight: "bold",
    fontSize: "17px",
    marginBottom: "0"
  },
  textLine: { width: "210px" },
  bellIcon: { fill: "green" },
  bellContainer: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    padding: "10px 40px",
    width: "100%"
  },
  disapproveBtn: {
    "&:hover": { background: "red" },
    background: "red",
    color: "#fff"
  },

  requestDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 0",
    alignItems: "center"
  },
  approveBtn: {
    "&:hover": { background: "green" },
    background: "green",
    color: "#fff"
  },
  userButton: {
    color: "#000"
  },
  dailogTitle: { color: "green", textAlign: "center" },

  logoutLink: {
    color: "#000",
    cursor: "pointer",
    padding: "10px 20px"
  }
});

const UserMenu: React.FC = () => {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { user, signOut } = useAppState();
  const { localTracks } = useVideoContext();
  let syncClient: any;
  const [open, setOpen] = React.useState(false);
  const [syncObject, setSyncObject] = useState<any>(null);
  const [newRequest, setNewRequest] = useState<any>([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const roomName: any =
    localStorage?.getItem("roomName") != ""
      ? localStorage?.getItem("roomName")
      : "test";
  const rejectInvitations = useCallback(
    (index: any, rowIndex: any) => {
      // syncObject.list(roomName).then(function(list: any) {
      const mutatorFunction = (currentValue: any) => {
        currentValue.status = 2;
        return currentValue;
      };
      syncObject
        .mutate(index, mutatorFunction, { ttl: 86400 })
        .then((item: any) => {
          setNewRequest((current: any) =>
            current.filter((element: any, index: any) => {
              return index !== rowIndex;
            })
          );
        })
        .catch((error: any) => {
          console.error("List Item mutate() failed", error);
        });
    },
    [syncObject, setNewRequest, newRequest]
  );
  const acceptInvitations = useCallback(
    (index: any, rowIndex: any) => {
      const mutatorFunction = (currentValue: any) => {
        currentValue.status = 1;
        return currentValue;
      };
      syncObject
        .mutate(index, mutatorFunction, { ttl: 86400 })
        .then((item: any) => {
          setNewRequest((current: any) =>
            current.filter((element: any, index: any) => {
              return index !== rowIndex;
            })
          );
        })
        .catch((error: any) => {
          console.error("List Item mutate() failed", error);
        });
      // });
    },
    [syncObject, newRequest, setNewRequest]
  );

  const [menuOpen, setMenuOpen] = useState(false);
  const [isListSubscribed, setListSubscribed] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const { authToken } = useContext(TokenContextProvider);
  const roomLink = "/myroom/";
  const handleSignOut = useCallback(() => {
    localTracks.forEach(track => track.stop());
    signOut?.();
  }, [localTracks, signOut]);

  const subsList = () => {
    const URLRoomToken =
      localStorage?.getItem("URLRoomToken") != ""
        ? localStorage?.getItem("URLRoomToken")
        : "";
    setListSubscribed(true);
    if (
      URLRoomToken &&
      URLRoomToken != "" &&
      URLRoomToken != "undefined" &&
      URLRoomToken != "null"
    ) {
      const roomName: any =
        localStorage?.getItem("roomName") != ""
          ? localStorage?.getItem("roomName")
          : "";
      const syncTokenString: any =
        localStorage?.getItem("syncToken") != ""
          ? localStorage?.getItem("syncToken")
          : "";
      // if (roomName == "") return;
      // if (syncTokenString == "") return;
      const syncClient2 = new SyncClient(syncTokenString);

      syncClient2
        .list(roomName)
        .then(function(list) {
          console.log("listtt video ", list);
          // list.removeList();
          setSyncObject(list);
          const pageHandler = (paginator: any) => {
            paginator.items.forEach((item: any) => {
              if (!item.isLocal && item.data.status == 0) {
                setNewRequest((oldd: any) => [...oldd, item]);
                setOpen(true);
              } else {
                list.remove(item.index);
              }
            });
            return paginator.hasNextPage
              ? paginator.nextPage().then(pageHandler)
              : null;
          };
          list
            .getItems({ from: 0, order: "asc", limit: 10 })
            .then(pageHandler)
            .catch(error => {
              console.error("List getItems() failed", error);
            });

          list.on("itemAdded", args => {
            if (!args.isLocal) {
              setNewRequest((oldd: any) => {
                return [...oldd, args.item];
              });
            }
            setOpen(true);
          });
          list.on("itemRemoved", args => {
            setNewRequest((current: any) => {
              return current.filter((element: any, index: any) => {
                return element.index !== args.index;
              });
            });
          });
        })
        .catch(function(error) {});
    } else {
      fetch(
        `${process.env.REACT_APP_API_URL}api/sync/token`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `${authToken}`
          }
        }
      )
        .then(response => response.json())
        .then(data => {
          const roomName: any =
            localStorage?.getItem("roomName") != ""
              ? localStorage?.getItem("roomName")
              : "test";
          //props.startLoading(false);
          const syncClient2 = new SyncClient(data.token);

          syncClient2
            .list(roomName)
            .then(function(list) {
              // list.removeList();
              setSyncObject(list);
              const pageHandler = (paginator: any) => {
                paginator.items.forEach((item: any) => {
                  if (!item.isLocal && item.data.status == 0) {
                    setNewRequest((oldd: any) => [...oldd, item]);
                    setOpen(true);
                  } else {
                    list.remove(item.index);
                  }
                });
                return paginator.hasNextPage
                  ? paginator.nextPage().then(pageHandler)
                  : null;
              };
              list
                .getItems({ from: 0, order: "asc", limit: 10 })
                .then(pageHandler)
                .catch(error => {});

              list.on("itemAdded", args => {
                console.log("newtonsssss", args.item, "hello kidooo add hua h");
                if (!args.isLocal) {
                  setNewRequest((oldd: any) => [...oldd, args.item]);
                }
                setOpen(true);
              });
              list.on("itemRemoved", args => {
                setNewRequest((current: any) => {
                  return current.filter((element: any, index: any) => {
                    return element.index !== args.index;
                  });
                });
              });
            })
            .catch(function(error) {});
        });
    }
  };

  useEffect(() => {
    if (!isListSubscribed) {
      subsList();
    }
    if (!newRequest || (newRequest && newRequest?.length < 1)) {
      setOpen(false);
    }
  }, [
    subsList,
    setOpen,
    newRequest
    // setNewRequest,
  ]);

  if (process.env.REACT_APP_SET_AUTH === "passcode") {
    return (
      <div className={classes.userContainer}>
        {newRequest && newRequest.length > 0 ? (
          <>
            {/* <div
              className={classes.bellContainer + " bellIconcont"}
              onClick={(event: any) => {
                setOpen(true);
              }}
            >
              <BellIcon />
            </div> */}
          </>
        ) : null}
        {/* <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className={classes.dailogTitle}>
            {"!! Video Join Request !!"}
          </DialogTitle>
          <DialogContent style={{ width: 430 }}>
            <DialogContentText id="alert-dialog-slide-description">
              {newRequest && newRequest.length > 0 ? (
                <>
                  <div className={classes.requestDiv}>
                    There is a new request in your room please join your room
                    and check it.
                  </div>
                </>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link href="/myroom" className={classes.logoutLink}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                id="clear"
                // className={classes.continueButton}
              >
                Join My Room
              </Button>
            </Link>
          </DialogActions>
        </Dialog> */}
        {/* <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className={classes.dailogTitle}>
            {"!! Waiting for you !!"}
          </DialogTitle>
          <DialogContent style={{ width: 430 }}>
            <DialogContentText id="alert-dialog-slide-description">
              {newRequest &&
                newRequest.map((item: any, ind: any) => (
                  <>
                    <div className={classes.requestDiv}>
                      <span className={classes.textLine}>
                        {" "}
                        {item.data.name} needs to join you
                      </span>
                      <Button
                        className={classes.disapproveBtn}
                        onClick={() => {
                          rejectInvitations(item.index, ind);
                        }}
                      >
                        Reject
                      </Button>
                      <Button
                        className={classes.approveBtn}
                        onClick={() => {
                          acceptInvitations(item.index, ind);
                        }}
                      >
                        Allow
                      </Button>
                    </div>
                  </>
                ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog> */}
      </div>
    );
  }

  return null;
};

export default UserMenu;
