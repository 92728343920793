import { useCallback, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import TokenContextProvider from "../../TokenContext";

// const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';

const endpoint = `${process.env.REACT_APP_API_URL}api/video/token`;

export function getPasscode() {
  const match = window.location.search.match(/passcode=(.*)&?/);
  const passcode = match ? match[1] : window.sessionStorage.getItem("passcode");
  return passcode;
}

export function fetchToken(
  URLRoomKey: string,
  URLRoomToken?: string,
  mainServerUserId?: string,
  roomGroupId?: string,
  authToken?: string,
  identity?: string
) {
  let endpoint = "";
  if (URLRoomToken != "") {
    endpoint = `${process.env.REACT_APP_API_URL}api/video/token/direct`;
  } else if (authToken !== "") {
    endpoint = `${process.env.REACT_APP_API_URL}api/video/token`;
  } else {
    endpoint = `${process.env.REACT_APP_API_URL}api/video/token/web`;
  }
  return fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `${authToken}`
    },
    body: JSON.stringify({
      URLRoomKey,
      URLRoomToken,
      mainServerUserId,
      roomGroupId,
      create_conversation:
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true",
      userType: "provider",
      identity: identity,
      test: "test"
    })
  });
}

export function verifyPasscode(userName: string, password?: string) {
  return fetch(`${process.env.REACT_APP_API_URL}api/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: userName,
      password: password,
      applicationType: "Web"
    })
  });
}

export function getErrorMessage(message: string) {
  switch (message) {
    case "passcode incorrect":
      return "Passcode is incorrect";
    case "passcode expired":
      return "Passcode has expired";
    default:
      return message;
  }
}

export default function usePasscodeAuth() {
  const history = useHistory();
  const { updateAuthToken, authToken } = useContext(TokenContextProvider);

  const [user, setUser] = useState<{
    displayName: undefined;
    photoURL: undefined;
    passcode: string;
  } | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const getToken = useCallback(
    (
      URLRoomKey: string,
      URLRoomToken?: string,
      mainServerUserId?: string,
      roomGroupId?: string,
      identity?: string,
      passcode?: string
    ) => {
      // return true;
      return fetchToken(
        URLRoomKey,
        URLRoomToken,
        mainServerUserId,
        roomGroupId,
        authToken,
        identity
      )
        .then(async res => {
          if (res.ok) {
            // localStorage.setItem("syncToken", res?.syncToken);
            return res;
          }
          const json = await res.json();
          const errorMessage = getErrorMessage(
            json.error?.message || res.statusText
          );
          throw Error(errorMessage);
        })
        .then(res => res.json());
    },
    [user]
  );

  const updateRecordingRules = useCallback(
    async (room_sid, rules) => {
      const endpoint = `${process.env.REACT_APP_API_URL}api/video/recordingrules`;
      console.log("HIIIIIIII");
      return fetch(endpoint, {
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ room_sid, rules, passcode: user?.passcode }),
        method: "POST"
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.error?.message ||
              "There was an error updating recording rules"
          );
          error.code = jsonResponse.error?.code;

          return Promise.reject(error);
        }

        return jsonResponse;
      });
    },
    [user]
  );

  useEffect(() => {
    const passcode = getPasscode();

    // if (password) {
    //   verifyPasscode(userName, password)
    //     .then(verification => {
    //       if (verification?.isValid) {
    if (localStorage.getItem("token")) {
      setUser({ passcode: true } as any);
    }
    //         window.sessionStorage.setItem('passcode', passcode);
    //         history.replace(window.location.pathname);
    //       }
    //     })
    //     .then(() => setIsAuthReady(true));
    // } else {
    // }
    setIsAuthReady(true);
  }, [history]);

  const signIn = useCallback((userName: string, password?: string) => {
    return verifyPasscode(userName, password)
      .then(verification => {
        return verification.json();
        // if (verification?.) {
        //   setUser({ userName } as any);
        //   // window.sessionStorage.setItem('passcode', verification);
        // } else {
        //   throw new Error(getErrorMessage(verification?.error));
        // }
      })
      .then(res => {
        if (res?.code === 200) {
          updateAuthToken(res?.token);
          setUser({ userName: true } as any);
          localStorage.setItem("token", res?.token);
          localStorage.setItem("email", res?.user?.email);
          localStorage.setItem("name", res?.user?.name);
          localStorage.setItem("phone", res?.user?.phone);
          localStorage.setItem("userRoomKey", res?.user?.room_key);
          localStorage.setItem("userRoomSid", res?.user?.room?.room_sid);
          localStorage.setItem("userID", res?.user?.id);
          localStorage.setItem("identity", res?.identity);
          localStorage.setItem("roomName", res?.user?.room?.room_name);
          localStorage.setItem("userRole", res?.user?.role);
          window.sessionStorage.setItem("passcode", res?.token);
        } else {
          throw new Error(getErrorMessage(res?.message));
        }
      });
  }, []);

  const setUserDetail = useCallback(() => {
    setUser({ userName: true } as any);
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    localStorage.clear();
    window.sessionStorage.removeItem("passcode");
    return Promise.resolve();
  }, []);

  return {
    user,
    isAuthReady,
    getToken,
    signIn,
    signOut,
    setUserDetail,
    updateRecordingRules
  };
}
