import React, { useState, createContext } from "react";
import ReactDOM from "react-dom";

import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

import App from "./App";
import AppStateProvider, { useAppState } from "./state";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import LoginPage from "./components/LoginPage/LoginPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PrivateRouteAdmin from "./components/PrivateRouteAdmin/PrivateRouteAdmin";
import theme from "./theme";
import "./types";
import { ChatProvider } from "./components/ChatProvider";
import { ParticipantProvider } from "./components/ParticipantProvider";
import { VideoProvider } from "./components/VideoProvider";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import UnsupportedBrowserWarning from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import UniqueNameContext from "./UniqueNameContext";
import TokenContext from "./TokenContext";
import UserPage from "./components/UserPage/UserPage";
import AdminUsersPage from "./components/AdminUsersPage/AdminUsersPage";
import AddUser from "./components/AddUser/AddUser";
import EditUser from "./components/EditUser/EditUser";
import ProviderEdit from "./components/ProviderEdit/ProviderEdit";
import ProviderAdd from "./components/ProviderAdd/ProviderAdd";
import AdminRoomsPage from "./components/AdminRoomsPage/AdminRoomsPage";
import RoomsAdd from "./components/RoomsAdd/RoomsAdd";
import ChatWindow from "./components/ChatWindow/ChatWindow";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import fetchIntercept from "fetch-intercept";
import Settings from "./components/Settings/Settings";
import ChangePassword from "./components/ChangePassword/ChangePassword";

fetchIntercept.register({
  request: function(url, config) {
    return [url, config];
  },

  requestError: function(error) {
    return Promise.reject(error);
  },

  response: function(response) {
    if (response?.status === 401) {
      localStorage.clear();
      window.sessionStorage.removeItem("passcode");
    }
    // if(response)
    // Modify the reponse object
    return response;
  },

  responseError: function(error) {
    // Handle an fetch error
    return Promise.reject(error);
  }
});

const ChatWindowApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) =>
    setUniqueNameC("CH817607b6dd0b47709ef6a7c775abc89d");

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <ChatWindow isChatWindowOpen={true} />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};
const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <App />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};

const UserApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <UserPage />
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};

const ProviderApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <AdminUsersPage />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};

const RoomsApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <AdminRoomsPage />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};

const UserAddApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <AddUser />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};
const SettingsApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <Settings />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};
const ChangePasswordApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <ChangePassword />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};
const RoomsAddApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <RoomsAdd />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};
const ProviderAddApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <ProviderAdd />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};
const ProviderEditApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <ProviderEdit />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};
const UserEditApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [uniqueNameC, setUniqueNameC] = useState("");

  const updateUniqueNameC = (name: any) => setUniqueNameC(name);

  return (
    <UniqueNameContext.Provider value={{ uniqueNameC, updateUniqueNameC }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ParticipantProvider>
          <ChatProvider>
            <EditUser />
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </UniqueNameContext.Provider>
  );
};
export const ReactApp = () => {
  const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: "30px",
    // you can also just use 'scale'
    transition: transitions.SCALE
  };
  // const { error, setError } = useAppState();
  // unregister();
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("token") || ""
  );
  const updateAuthToken = (name: any) => {
    setAuthToken(name);
  };

  return (
    <TokenContext.Provider value={{ authToken, updateAuthToken }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <UnsupportedBrowserWarning>
          <Router basename={""}>
            <AppStateProvider>
              <Switch>
                {/* <PrivateRoute exact path="/">
              <VideoApp />
            </PrivateRoute> */}
                <Route path="/client/room/:URLRoomKey/:mainServerUserId/:roomGroupId/:clientFriendlyName">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <VideoApp />
                  </AlertProvider>
                </Route>
                <Route path="/client/room/:URLRoomKey/:mainServerUserId/:roomGroupId">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <VideoApp />
                  </AlertProvider>
                </Route>
                <Route path="/room/:URLRoomKey/:clientFriendlyName">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <VideoApp />
                  </AlertProvider>
                </Route>
                <Route path="/room/:URLRoomKey">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <VideoApp />
                  </AlertProvider>
                </Route>
                <Route path="/provider/room/:URLRoomToken/:clientFriendlyName">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <VideoApp />
                  </AlertProvider>
                </Route>

                <Route path="/provider/room/:URLRoomToken">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <VideoApp />
                  </AlertProvider>
                </Route>
                <Route path="/chat">
                  <ChatWindowApp />
                </Route>

                <PrivateRoute path="/dashboard">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <UserApp />
                  </AlertProvider>
                </PrivateRoute>
                <PrivateRoute path="/user/add">
                  <UserAddApp />
                </PrivateRoute>
                <PrivateRoute path="/user/edit/:recordID">
                  <UserEditApp />
                </PrivateRoute>

                <PrivateRoute path="/myroom">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <VideoApp />
                  </AlertProvider>
                </PrivateRoute>
                <PrivateRouteAdmin path="/providers/edit/:providerID">
                  <ProviderEditApp />
                </PrivateRouteAdmin>
                <PrivateRouteAdmin path="/providers/add">
                  <ProviderAddApp />
                </PrivateRouteAdmin>

                <PrivateRouteAdmin path="/providers">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <ProviderApp />
                  </AlertProvider>
                </PrivateRouteAdmin>

                <PrivateRouteAdmin path="/rooms/add">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <RoomsAddApp />
                  </AlertProvider>
                </PrivateRouteAdmin>

                <PrivateRouteAdmin path="/rooms">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <RoomsApp />
                  </AlertProvider>
                </PrivateRouteAdmin>

                <PrivateRouteAdmin path="/settings">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <SettingsApp />
                  </AlertProvider>
                </PrivateRouteAdmin>

                <PrivateRoute path="/change-password">
                  <AlertProvider template={AlertTemplate} {...options}>
                    <ChangePasswordApp />
                  </AlertProvider>
                </PrivateRoute>

                <Route path="/login">
                  <LoginPage />
                </Route>
                <Route path="/req/:passToken">
                  <ForgotPassword />
                </Route>
                <Redirect to="/login" />
              </Switch>
            </AppStateProvider>
          </Router>
        </UnsupportedBrowserWarning>
      </MuiThemeProvider>
    </TokenContext.Provider>
  );
};

ReactDOM.render(<ReactApp />, document.getElementById("root"));
