import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Theme, Button } from "@material-ui/core";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack
} from "twilio-video";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { json } from "express";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name: string) {
  name = name.toUpperCase();
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: name.includes(" ")
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : `${name[0]}`
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    fontSize: "0.87rem !important",
    paddingTop: "5%",
    paddingLeft: "10px"
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #80808066",
    padding: "10px 0",
    alignContent: "center"
  },
  iconContainer: {
    display: "flex",
    paddingTop: "3%"
  },
  nameContainer: {
    display: "flex"
  },
  avatar: {
    width: "30px !important",

    height: "30px !important",
    fontSize: "13px !important"
  },
  disapproveBtn: {
    "&:hover": { background: "red" },
    background: "red",
    color: "#fff",
    marginLeft: "10px",
    borderRadius: "30px",
    minWidth: "auto"
  }
}));

interface MainParticipantInfoProps {
  participant: Participant;
  children?: React.ReactNode;
}

export default function ParticipantRowIcon({
  participant
}: MainParticipantInfoProps) {
  const classes = useStyles();

  const publications = usePublications(participant);
  const videoPublication = publications.find(
    p => !p.trackName.includes("screen") && p.kind === "video"
  );
  const isVideoEnabled = Boolean(videoPublication);
  const audioPublication = publications.find(p => p.kind === "audio");
  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;
  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );
  const { room } = useVideoContext();

  const handleDisconnect = async (participant: any) => {
    try {
      if (!room) {
        console.log("Room is null, unable to disconnect participant.");
        return;
      }
      const participantData = {
        name: participant.identity,
        sid: participant.sid,
        roomName: room.name,
        roomSid: room.sid
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/disconnectParticipant`,
        {
          method: "post",

          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(participantData)
        }
      );
      if (response.ok) {
        console.log("Participant disconnected successfully");
      } else {
        console.log("Participant disconnection failed");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  return (
    <div className={classes.rowContainer}>
      <div className={classes.nameContainer}>
        <Avatar
          {...stringAvatar(`${participant.identity}`)}
          className={classes.avatar}
        />

        <Typography className={classes.listItem} variant="h6">
          {participant.identity}
        </Typography>
      </div>
      <div className={classes.iconContainer}>
        <AudioLevelIndicator audioTrack={audioTrack} />
        <div>
          {!isVideoEnabled || isVideoSwitchedOff ? (
            <VideocamOffOutlinedIcon style={{ fontSize: "1.3rem" }} />
          ) : (
            <VideocamOutlinedIcon style={{ fontSize: "1.4rem" }} />
          )}
        </div>
        <Button
          size="small"
          className={classes.disapproveBtn}
          onClick={() => handleDisconnect(participant)}
        >
          <CallEndIcon style={{ fontSize: "large" }} />
        </Button>
      </div>
    </div>
  );
}
