import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  FormEvent
} from "react";
import { useAppState } from "../../state";
import { useHistory } from "react-router-dom";
import UserMenu from "../IntroContainer/UserMenu/UserMenu";
import Button from "@material-ui/core/Button";
import "../../App.css";
import { Theme, Grid, TextField, InputLabel } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Input from "@mui/material/Input";
import { makeStyles } from "@material-ui/core/styles";
import TokenContextProvider from "../../TokenContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const useStyles = makeStyles((theme: Theme) => ({
  butttonParent: {
    flexDirection: "row",
    columnGap: "15px"
  },
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgb(40, 42, 43)",
    height: "auto",
    flexDirection: "column"
  },

  phonInput: {
    // width: '100%',
    // border: '1px solid #000',
  },

  container: {
    position: "relative",
    width: "100%",
    flex: "1"
  },
  innerContainer: {
    display: "flex",
    height: "auto",
    borderRadius: "0",
    // boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    // overflow: 'hidden',
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      margin: "auto",
      maxWidth: "400px"
    }
  },
  swooshContainer: {
    background: "white",
    width: "100%",
    padding: "1em 4em",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "2em"
    }
  },

  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.5em 0 3.5em",
    "& div:not(:last-child)": {
      marginRight: "1em"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em 0 2em"
    }
  },
  textFieldContainer: {
    width: "100%"
  },
  continueButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  googleButton: {
    background: "white",
    color: "rgb(0, 94, 166)",
    borderRadius: "4px",
    border: "2px solid rgb(2, 122, 197)",
    margin: "1.8em 0 0.7em",
    textTransform: "none",
    boxShadow: "none",
    padding: "0.3em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "&:hover": {
      background: "white",
      boxShadow: "none"
    }
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    width: "30%",
    marginBottom: "1em",
    marginLeft: "35%"
  },
  gutterBottom: {
    marginBottom: "1em"
  },
  passcodeContainer: {
    minHeight: "120px"
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

const columns: any = [
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right"
  },
  {
    id: "size",
    label: "Size",
    minWidth: 170,
    align: "right"
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "right"
  }
];

const rows: any = [];

export default function ChangePassword() {
  const history = useHistory();
  const classes = useStyles();
  const { user, isAuthReady } = useAppState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disabled, setDisabled] = useState(false);
  const [tmpPwd, setTmpPwd] = useState("");

  const [showPassword1, setShowPassword1] = React.useState(false);
  const handleClickShowPassword1 = () => setShowPassword1(show => !show);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(show => !show);
  const [showPassword3, setShowPassword3] = React.useState(false);
  const handleClickShowPassword3 = () => setShowPassword3(show => !show);
  const [showPassword4, setShowPassword4] = React.useState(false);
  const handleClickShowPassword4 = () => setShowPassword4(show => !show);
  const [showPassword5, setShowPassword5] = React.useState(false);
  const handleClickShowPassword5 = () => setShowPassword5(show => !show);
  const [showPassword6, setShowPassword6] = React.useState(false);
  const handleClickShowPassword6 = () => setShowPassword6(show => !show);
  const [showPassword7, setShowPassword7] = React.useState(false);
  const handleClickShowPassword7 = () => setShowPassword7(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [showForm, setShowForm] = useState(false);
  const [formElements, setFormElements] = useState<{
    TWILIO_AUTH_TOKEN: string;
    TWILIO_API_KEY: string;
    TWILIO_API_SECRET: string;
    TWILIO_CHAT_SERVICE_SID: string;
    TWILIO_SYNC_SERVICE: string;
    TWILIO_ACCOUNT_SID: string;
    TWILIO_NUMBER: string;
    PASSWORD: string;
  }>({
    TWILIO_AUTH_TOKEN: "",
    TWILIO_API_KEY: "",
    TWILIO_API_SECRET: "",
    TWILIO_CHAT_SERVICE_SID: "",
    TWILIO_SYNC_SERVICE: "",
    TWILIO_ACCOUNT_SID: "",
    TWILIO_NUMBER: "",
    PASSWORD: ""
  });

  useEffect(() => {}, [formElements]);

  const { authToken } = useContext(TokenContextProvider);
  const token = localStorage.getItem("token");
  const addSettingsAction = useCallback(
    data => {
      if (authToken) {
        fetch(
          `${process.env.REACT_APP_API_URL}api/settings`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `${authToken}`
            },
            body: JSON.stringify(data)
          }
        )
          .then(response => response.json())
          .then(data => {
            console.log("daatatata--", data);
            if (data.code == 200) {
              alert(data?.message);
              // history.push("/conversation/dashboard");
              setShowForm(false);
            } else {
              alert(data?.message);
            }
          });
      }
    },
    [page, rowsPerPage, authToken]
  );

  const fetchRecord = useCallback(() => {
    if (authToken) {
      fetch(
        `${process.env.REACT_APP_API_URL}api/settings`,

        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: `${authToken}`
          }
        }
      )
        .then(response => response.json())
        .then(data => {
          //props.startLoading(false);

          const result = data?.existingSettings;
          console.log("datadata", result);
          const formData = {
            TWILIO_AUTH_TOKEN: result?.twilio_auth_token,
            TWILIO_API_KEY: result?.twilio_api_key,
            TWILIO_API_SECRET: result?.twilio_api_secret,
            TWILIO_CHAT_SERVICE_SID: result?.twilio_chat_service_sid,
            TWILIO_SYNC_SERVICE: result?.twilio_sync_service,
            TWILIO_ACCOUNT_SID: result?.twilio_account_sid,
            TWILIO_NUMBER: result?.twilio_number,
            PASSWORD: ""
          };
          console.log("formdatataaaaa", formData);
          setFormElements(formData);
        });
    }
  }, [page, rowsPerPage, authToken]);

  useEffect(() => {
    fetchRecord();
  }, [fetchRecord]);

  /**
   * Call function to handle page navigation
   * @param {*} event
   * @param {*} value
   */
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const sendData = {
      PASSWORD: tmpPwd
    };

    if (!showForm) {
      if (authToken) {
        fetch(`${process.env.REACT_APP_API_URL}api/password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `${authToken}`
          },
          body: JSON.stringify(sendData)
        })
          .then(response => response.json())
          .then(data => {
            if (data.code === 200) {
              setShowForm(true);
              setTmpPwd("");
            } else {
              alert(data?.message);
            }
          });
      }
    } else {
      const sendData = {
        TWILIO_AUTH_TOKEN: formElements.TWILIO_AUTH_TOKEN,
        TWILIO_API_KEY: formElements.TWILIO_API_KEY,
        TWILIO_API_SECRET: formElements.TWILIO_API_SECRET,
        TWILIO_CHAT_SERVICE_SID: formElements.TWILIO_CHAT_SERVICE_SID,
        TWILIO_SYNC_SERVICE: formElements.TWILIO_SYNC_SERVICE,
        TWILIO_ACCOUNT_SID: formElements.TWILIO_ACCOUNT_SID,
        TWILIO_NUMBER: formElements.TWILIO_NUMBER,
        PASSWORD: formElements.PASSWORD
      };
      addSettingsAction(sendData);
    }
  };
  /**
   * Call function to handle page size
   * @param {*} event
   * @param {*} value
   */
  if (!isAuthReady) {
    return null;
  }

  const handleChange = (field: string, value: string) => {
    setFormElements({
      ...formElements,
      [field]: value
    });
  };
  return (
    <>
      <div className={classes.background}>
        <UserMenu />
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <div className={classes.swooshContainer}>
              <div style={{ textAlign: "right" }}></div>
              <Typography variant="h5" className={classes.gutterBottom}>
                Twilio Settings
              </Typography>
              {showForm ? (
                <>
                  <form onSubmit={handleSubmit}>
                    <div className={classes.inputContainer}>
                      <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-user-name">
                          TWILIO AUTH TOKEN
                        </InputLabel>
                        <Input
                          id="input-user-name"
                          // variant="outlined"
                          type={showPassword1 ? "text" : "password"}
                          fullWidth
                          size="small"
                          onChange={event =>
                            handleChange(
                              "TWILIO_AUTH_TOKEN",
                              event.target.value
                            )
                          }
                          value={formElements.TWILIO_AUTH_TOKEN}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword1}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword1 ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                      <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-room-name">
                          TWILIO API KEY
                        </InputLabel>
                        <Input
                          type={showPassword2 ? "text" : "password"}
                          autoCapitalize="false"
                          id="input-room-name"
                          // variant="outlined"
                          fullWidth
                          size="small"
                          onChange={event =>
                            handleChange("TWILIO_API_KEY", event.target.value)
                          }
                          value={formElements.TWILIO_API_KEY}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword2}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword2 ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                    </div>
                    <div className={classes.inputContainer}>
                      <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-user-name">
                          TWILIO API SECRET
                        </InputLabel>
                        <Input
                          type={showPassword3 ? "text" : "password"}
                          id="input-user-name"
                          // variant="outlined"
                          fullWidth
                          size="small"
                          onChange={event =>
                            handleChange(
                              "TWILIO_API_SECRET",
                              event.target.value
                            )
                          }
                          value={formElements.TWILIO_API_SECRET}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword3}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword3 ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                      <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-user-name">
                          TWILIO CHAT SERVICE SID
                        </InputLabel>
                        <Input
                          type={showPassword4 ? "text" : "password"}
                          id="input-user-name"
                          // variant="outlined"
                          fullWidth
                          size="small"
                          onChange={event =>
                            handleChange(
                              "TWILIO_CHAT_SERVICE_SID",
                              event.target.value
                            )
                          }
                          value={formElements.TWILIO_CHAT_SERVICE_SID}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword4}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword4 ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                    </div>
                    <div className={classes.inputContainer}>
                      <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-user-name">
                          TWILIO SYNC SERVICE
                        </InputLabel>
                        <Input
                          type={showPassword5 ? "text" : "password"}
                          id="input-user-name"
                          // variant="outlined"
                          fullWidth
                          size="small"
                          onChange={event =>
                            handleChange(
                              "TWILIO_SYNC_SERVICE",
                              event.target.value
                            )
                          }
                          value={formElements.TWILIO_SYNC_SERVICE}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword5}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword5 ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                      <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-user-name">
                          TWILIO ACCOUNT SID
                        </InputLabel>
                        <Input
                          type={showPassword6 ? "text" : "password"}
                          id="input-user-name"
                          // variant="outlined"
                          fullWidth
                          size="small"
                          onChange={event =>
                            handleChange(
                              "TWILIO_ACCOUNT_SID",
                              event.target.value
                            )
                          }
                          value={formElements.TWILIO_ACCOUNT_SID}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword6}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword6 ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                    </div>
                    <div className={classes.inputContainer}>
                      <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-user-name">
                          TWILIO NUMBER
                        </InputLabel>
                        <Input
                          type={showPassword7 ? "text" : "password"}
                          id="input-user-name"
                          // variant="outlined"
                          fullWidth
                          size="small"
                          onChange={event =>
                            handleChange("TWILIO_NUMBER", event.target.value)
                          }
                          value={formElements.TWILIO_NUMBER}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword7}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword7 ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                      <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-user-name">
                          PASSWORD
                        </InputLabel>
                        <TextField
                          type="password"
                          id="input-user-name"
                          variant="outlined"
                          fullWidth
                          size="small"
                          onChange={event =>
                            handleChange("PASSWORD", event.target.value)
                          }
                          value={formElements.PASSWORD}
                        />
                      </div>
                    </div>
                    <Grid container justifyContent="center">
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={
                          formElements.TWILIO_AUTH_TOKEN === "" ||
                          formElements.TWILIO_API_KEY === "" ||
                          formElements.TWILIO_API_SECRET === "" ||
                          formElements.TWILIO_CHAT_SERVICE_SID === "" ||
                          formElements.TWILIO_SYNC_SERVICE === "" ||
                          formElements.TWILIO_ACCOUNT_SID === "" ||
                          formElements.TWILIO_NUMBER === "" ||
                          formElements.PASSWORD === "" ||
                          disabled
                        }
                        className={classes.continueButton}
                      >
                        Update
                      </Button>
                    </Grid>
                  </form>
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmit}>
                    <div className={classes.centerContainer}>
                      <div className={classes.textFieldContainer}>
                        <InputLabel shrink htmlFor="input-user-name">
                          PASSWORD
                        </InputLabel>
                        <TextField
                          type="password"
                          id="input-user-name"
                          variant="outlined"
                          fullWidth
                          size="small"
                          onChange={event => setTmpPwd(event.target.value)}
                          value={tmpPwd}
                        />
                      </div>
                    </div>

                    <Grid container justifyContent="center">
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={tmpPwd === "" || disabled}
                        className={classes.continueButton}
                      >
                        Verify
                      </Button>
                    </Grid>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
