import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";

import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: "white",
      "&:hover": {
        background: "#600101"
      }
    }
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();

  const handleDisconnect = async () => {
    try {
      if (!room) {
        console.log("Room is null, unable to disconnect participant.");
        return;
      }
      const roomData = {
        roomName: room.name,
        roomSid: room.sid
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/disconnect-all-participant`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(roomData)
        }
      );
      if (response.ok) {
        console.log("Room disconnected successfully");
      } else {
        console.log("Room disconnection failed");
      }
      room!.disconnect();
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <Button
      onClick={() => handleDisconnect()}
      className={clsx(classes.button, props.className)}
      data-cy-disconnect
    >
      Disconnect
    </Button>
  );
}
