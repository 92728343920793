import React from 'react';

const UniqueNameContext = React.createContext({
    uniqueNameC: '', updateUniqueNameC: (data: any) => { },
});;

export const RoutingProvider = UniqueNameContext.Provider
export const RoutingConsumer = UniqueNameContext.Consumer

export default UniqueNameContext;
