import React, { useState, useEffect, useCallback, useContext } from "react";
import { useAppState } from "../../state";
import UserMenu from "../IntroContainer/UserMenu/UserMenu";
import Button from "@material-ui/core/Button";
import "../../App.css";

import { useHistory } from "react-router-dom";
import {
  FormControl,
  Theme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Link
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import TokenContextProvider from "../../TokenContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useAlert } from "react-alert";
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles((theme: Theme) => ({
  phonInput: {
    // width: '100%',
    // border: '1px solid #000',
  },
  butttonParent: {
    flexDirection: "row",
    columnGap: "15px"
  },
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgb(40, 42, 43)",
    height: "auto",
    flexDirection: "column"
  },
  container: {
    position: "relative",
    width: "100%",
    flex: "1"
  },
  innerContainer: {
    display: "flex",
    height: "auto",
    borderRadius: "0",
    // boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    // overflow: 'hidden',
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      margin: "auto",
      maxWidth: "400px"
    }
  },
  swooshContainer: {
    background: "white",
    width: "100%",
    padding: "1em 4em",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "2em"
    }
  },

  googleButton: {
    background: "white",
    color: "rgb(0, 94, 166)",
    borderRadius: "4px",
    border: "2px solid rgb(2, 122, 197)",
    margin: "1.8em 0 0.7em",
    textTransform: "none",
    boxShadow: "none",
    padding: "0.3em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "&:hover": {
      background: "white",
      boxShadow: "none"
    }
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  gutterBottom: {
    marginBottom: "1em"
  },
  passcodeContainer: {
    minHeight: "120px"
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

const columns: any = [
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right"
  },
  {
    id: "size",
    label: "Size",
    minWidth: 170,
    align: "right"
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "right"
  }
];

const rows: any = [];

export default function AdminUsersPage() {
  const alert = useAlert();
  const classes = useStyles();
  const { user, isAuthReady } = useAppState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [conversationList, setConversationList] = useState<any>(null);
  const [directPhone, setDirectPhone] = useState<any>("+1");
  const { authToken } = useContext(TokenContextProvider);
  const token = localStorage.getItem("token");
  const getuserList = useCallback(() => {
    if (authToken) {
      fetch(
        `${process.env.REACT_APP_API_URL}api/user-list`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `${authToken}`
          },
          body: JSON.stringify({
            skip: page,
            take: rowsPerPage
          })
        }
      )
        .then(response => response.json())
        .then(data => {
          //props.startLoading(false);
          setConversationList(data);
        });
    }
  }, [page, rowsPerPage, authToken]);

  const deleteUserAction = useCallback(
    (id: any) => {
      if (authToken) {
        fetch(
          `${process.env.REACT_APP_API_URL}api/user/${id}`,

          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              authorization: `${authToken}`
            }
          }
        )
          .then(response => response.json())
          .then(data => {
            if (data.status == 200) {
              getuserList();
            }
          });
      }
    },
    [page, rowsPerPage, authToken]
  );

  const sendInvite = useCallback(
    id => {
      if (authToken) {
        fetch(
          `${process.env.REACT_APP_API_URL}api/invite-user-by-admin`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `${authToken}`
            },
            body: JSON.stringify({
              id: id
            })
          }
        )
          .then(response => response.json())
          .then(data => {
            alert.show("Invitation has been sent", {
              timeout: 2000,
              type: "success"
            });
          });
      }
    },
    [authToken]
  );

  useEffect(() => {
    getuserList();
  }, [getuserList]);

  /**
   * Call function to handle page navigation
   * @param {*} event
   * @param {*} value
   */
  const handleChangePage = (event: any, value: any) => {
    setPage(value);
  };

  /**
   * Call function to handle page size
   * @param {*} event
   * @param {*} value
   */
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
  };
  const history = useHistory();
  // const location = useLocation<{ from: Location }>();
  // const [userName, setUserName] = useState('');
  // const [password, setPassword] = useState('');
  // const [authError, setAuthError] = useState<Error | null>(null);

  // const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  const roomKey = localStorage.getItem("roomKey");
  // const roomLinkOpen = "https://www.talbotcenter.com/room/" + roomKey;
  const roomLink = "/myroom/";
  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(userName, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/' });
  //     })
  //     .catch(err => setAuthError(err));
  // };

  if (!isAuthReady) {
    return null;
  }

  return (
    <>
      <div className={classes.background}>
        <UserMenu />
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <div className={classes.swooshContainer}>
              <div style={{ textAlign: "right" }} className="sub-menu-btns">
                <Link href={"providers/add"}>
                  <Button
                    className="whitespace-nowrap"
                    variant="contained"
                    color="secondary"
                    type="button"
                  >
                    Add New Provider
                  </Button>
                </Link>
              </div>
              <Typography variant="h5" className={classes.gutterBottom}>
                Provider List
              </Typography>
              <TableContainer>
                <Table
                  className="min-w-xl conversation-list-table"
                  aria-labelledby="tableTitle"
                >
                  <TableHead>
                    <TableRow className="h-64">
                      {/* <TableCell>Id</TableCell> */}
                      <TableCell>Name</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Room name</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {conversationList?.data?.map((iteam: any, index: any) => (
                      <TableRow
                        className="h-64"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell>{iteam?.name}</TableCell>
                        <TableCell>{iteam?.userName}</TableCell>
                        <TableCell>{iteam?.email}</TableCell>
                        <TableCell>{iteam?.phone}</TableCell>
                        <TableCell>{iteam?.room?.room_name}</TableCell>

                        <TableCell>
                          <FormControl className={classes.butttonParent}>
                            <Button
                              className="whitespace-nowrap"
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                sendInvite(iteam.id);
                              }}
                              type="button"
                            >
                              Send Invite
                            </Button>
                            <Link href={"/providers/edit/" + iteam.id}>
                              <Button
                                className="whitespace-nowrap"
                                variant="contained"
                                color="secondary"
                                type="button"
                              >
                                Edit
                              </Button>
                            </Link>
                            <Link
                              onClick={() => {
                                deleteUserAction(iteam.id);
                              }}
                              href={"#"}
                            >
                              <Button
                                className="whitespace-nowrap"
                                variant="contained"
                                color="secondary"
                                type="button"
                              >
                                Delete
                              </Button>
                            </Link>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={conversationList?.count ? conversationList?.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* <TablePagination
                count={conversationList?.count ? conversationList?.count : 0}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                rowsPerPageOptions={[10, 25, 100]}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
