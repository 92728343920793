import { Callback } from "../../../types";
import { isMobile } from "../../../utils";
import Video, { ConnectOptions, LocalTrack, Room } from "twilio-video";
import { VideoRoomMonitor } from "@twilio/video-room-monitor";
import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { SyncClient } from "twilio-sync";
import TokenContextProvider from "../../../TokenContext";
import UniqueNameContextProvider from "../../../UniqueNameContext";

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(
  localTracks: LocalTrack[],
  onError: Callback,
  options?: ConnectOptions
) {
  const { uniqueNameC } = useContext(UniqueNameContextProvider);
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [videoToken, setVideoToken] = useState<any>(null);
  const optionsRef = useRef(options);
  const { updateAuthToken, authToken } = useContext(TokenContextProvider);
  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const onITemUpdateRemoved = (
    list: any,
    args: any,
    identity: any,
    token: any
  ) => {
    const dataa = args.item.data;
    if (dataa.name == identity) {
      if (dataa.status == 1) {
        connectfinalvideo(token);
      } else if (dataa.status == 2) {
        onError(new Error("Provider has rejected your request"));
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      list.remove(args.item.index);
    }
  };
  const SyncFunc = (syncToken: any, token: any) => {
    setVideoToken(token);
    const syncClient = new SyncClient(syncToken);
    const identity = localStorage?.getItem("identity");
    const roomName: any =
      localStorage?.getItem("roomName") != ""
        ? localStorage?.getItem("roomName")
        : "test";
    syncClient
      .list(roomName)
      .then(function(list) {
        console.log("listt listt", list, token, roomName);
        list
          .push({ name: identity, status: 0 }, { ttl: 86400 })
          .then(item => {})
          .catch(error => {
            console.error("List Item push() failed", error, roomName);
          });
        list.on("itemRemoved", args => {
          onITemUpdateRemoved(list, args, identity, token);
        });
        list.on("itemUpdated", args => {
          onITemUpdateRemoved(list, args, identity, token);
        });
      })
      .catch(function(error) {
        console.log("Unexpected error", error);
      });
  };

  const connectfinalvideo = useCallback(
    videoToken => {
      return Video.connect(videoToken, {
        ...optionsRef.current,
        tracks: localTracks
      }).then(
        newRoom => {
          setRoom(newRoom);
          VideoRoomMonitor.registerVideoRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          // This app can add up to 16 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          newRoom.setMaxListeners(30);

          newRoom.once("disconnected", () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(null));
            window.removeEventListener("beforeunload", disconnect);

            if (isMobile) {
              window.removeEventListener("pagehide", disconnect);
            }
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          newRoom.localParticipant.videoTracks.forEach(publication =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority("low")
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener("beforeunload", disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener("pagehide", disconnect);
          }
        },
        error => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [videoToken, localTracks, onError, setVideoToken]
  );

  const connect = useCallback(
    (token, URLRoomToken = "") => {
      setVideoToken(token);

      setIsConnecting(true);
      if (authToken == "" && URLRoomToken == "") {
        // onError(new Error("Please wait provider action"));
        SyncFunc(localStorage.getItem("syncToken"), token);
        return token;
      } else {
        connectfinalvideo(token);
      }
    },
    [localTracks, onError, setVideoToken]
  );
  return { room, isConnecting, connect };
}
